<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.fcl_storage_reservation") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-form layout="inline">
            <a-form-item>
              <a-input v-model:value="state.searchKey"
                       :placeholder="
                  $t('warehouse.reservation_number_or_cabinet_number')
                "
                       :allowClear="true"
                       @pressEnter="handleChange" />
            </a-form-item>
            <a-form-item>
              <a-button @click="handleChange"
                        type="primary">{{
                $t("common.query")
              }}</a-button>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col>
          <a-button type="primary"
                    @click="handleCreatePlan"
                    ghost>{{ $t("common.create") }}</a-button>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 :data-source="state.planList"
                 :scroll="{ x: true, y: wrap.contentHeight - 120 }"
                 :pagination="false"
                 size="small"
                 :loading="state.listLoading"
                 :rowKey="
            (record, index) => {
              return index;
            }
          "
                 class="product-table">
          <template #appointmentTime="{ record }">
            <span v-if="record.appointmentTime&&record.warehouseTimeZone">
              {{moment.tz(record.appointmentTime, record.warehouseTimeZone).format('YYYY-MM-DD')}}
              ({{$t('warehouse.local_time')}})
            </span>
          </template>
          <template #creationTime="{ record }">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
            <!-- ({{$t('warehouse.local_time')}}) -->
          </template>
          <template #lastModificationTime="{ record }">
            {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
          </template>

          <template #containerType="{ record }">
            {{ $t($enumLangkey("containerType", record.containerType)) }}
          </template>

          <template #status="{ record }">
            {{
              $t($enumLangkey("fullContainerInWarehouseType", record.status))
            }}
            <span v-if="record.isTimeOut">({{ $t('warehouse.timed_out') }})</span>
          </template>

          <template #transportRouteAndDestinationTypeCustom="{ record }">
            {{ $t($enumLangkey("transportRoutes", record.transportRoute)) }}
          </template>
          <template #operateCustom="{ record }">
            <router-link :to="{name:'transport_appointment_details',  params: {'id': record.id}}">
              <a-button type="ghost">{{$t('common.details')}}</a-button>
            </router-link>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="state.pageData"></CPager>
        </a-col>
      </a-row>
    </template>

  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { reactive, onActivated } from "vue";
import { Row, Col, Table, Input, Button, Form } from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import { getList } from "../../../api/modules/transportation/appointment";
import { getName } from "../../../utils/general";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment-timezone";

export default {
  name: "transport_appointment_list",
  components: {
    Content,
    CPager,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
  },
  setup () {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        title: () => vueI18n.t("warehouse.in_warehouse_code"),
        dataIndex: "warehouseNo",
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.reservation_number"),
        dataIndex: "appointmentNo",
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.date_of_reservation"),
        dataIndex: "appointmentTime",
        slots: {
          customRender: "appointmentTime",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.container_no"),
        dataIndex: "containerNo",
        slots: {
          customRender: "containerNo",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.container_type"),
        dataIndex: "containerType",
        slots: {
          customRender: "containerType",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.contain_in_plan_count"),
        dataIndex: "planNum",
        slots: {
          customRender: "planNum",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        dataIndex: "status",
        slots: {
          customRender: "status",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.date_created"),
        dataIndex: "creationTime",
        slots: {
          customRender: "creationTime",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.update_time"),
        dataIndex: "lastModificationTime",
        slots: {
          customRender: "lastModificationTime",
        },
        width: 180,
      },
      {
        title: '',
        dataIndex: "operateCustom",
        slots: {
          customRender: "operateCustom",
        },
        width: 100,
      },
    ];

    const state = reactive({
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      planList: [],
      listLoading: false,
      searchKey: null,
    });

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    };

    const handleChange = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      handleSearch();
    };

    const handleCreatePlan = async () => {
      router.push({ name: "transport_appointment_create" });
    };

    const handleSearch = async () => {
      let searchData = {
        searchKey: state.searchKey,
        ...state.pageData,
      };
      state.listLoading = true;
      getList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            state.planList = items;
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
          }
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    onActivated(async () => {
      handleSearch();
    });

    return {
      moment,
      columns,
      state,
      handlePage,
      handleSearch,
      handleCreatePlan,
      getLanguageName,
      handleChange,
    };
  },
};
</script>

<style lang="less" scoped>
</style>